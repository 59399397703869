<template>
  <b-card no-body>
    <div class="m-2">
        <b-row>
            <b-col
                cols="12"
                md="9"
                class="mb-1 d-flex align-items-center justify-content-start mb-md-0"
            >
                <div class="d-flex align-items-center justify-content-end">
                    <b-form-input 
                      v-model="searchQuery"
                      class="mr-1 d-inline-block"
                      :placeholder="$t('globalActions.find')"
                    />
                </div>
            </b-col>
            <!-- add  -->
            <b-col
             cols="12"
             md="3"
             class="mb-1 d-flex align-items-center justify-content-end mb-md-0"
            >
                <b-button
                    variant="primary"
                    :to="{ name: 'apps-transactions-evaluation-add'}"
                >
                    <feather-icon icon="PlusIcon" /> {{ $t('apps.transactions.evaluation.singular.create') }}
                </b-button>
            </b-col>
        </b-row>
    </div>
    <b-table
      id="refListTable"
      ref="refListTable"
      no-provider-paging
      no-provider-filtering
      :filter-included-fields="columnToBeFilter"
      :fields="tableColumns"
      :items="itemLists"
      :per-page="perPage"
      :current-page="currentPage"
      :filter="searchQuery"
      responsive
      primary-key="id"
      show-empty
      :busy="isBusy"
      :empty-text="$t('globalSingular.noMatching')"
      :sort-desc.sync="isSortDirDesc"
      class="position-relative"
      >
        <template #table-busy>
           <div class="my-2 text-center text-primary d-flex flex-column justify-content-center">
             <div class="mb-1 text">
                <b-spinner class="align-middle" /> 
             </div>
           </div>
        </template>

        <!-- Column: code -->
        <template #cell(code)="{ item }">
          <b-link
            :to="{ name: 'apps-transactions-evaluation-detail', params: { id: item.id }}"
            class="font-weight-bolder"
          >
            <span class="font-weight-bolder d-block text-nowrap">
              {{ item.code }}
            </span>
          </b-link>
        </template>

        <!-- Column: Date -->
        <template #cell(date)="{ item }">
          <span class="font-weight-bolder d-block text-nowrap">
            {{ formatDatepicker(item.date) }}
          </span>
        </template>

              <!-- Column: actions -->
      <template #cell(actions)="{item}">
        <div>
        <b-link
          :to="{ name: 'apps-transactions-purchase-eval-prints-eval', params: { id: item.id }}"
          class="font-weight-bolder"
        >
          <span class="font-weight-bolder d-block text-nowrap">
           <feather-icon
            icon="FileTextIcon"
            size="21"
          />
          </span>
        </b-link>
        </div>
      </template>
    </b-table>
  </b-card>
</template>
<script>
    import {
        BCard,
        BRow,
        BCol,
        BFormInput,
        BButton,
        BTable,
        BSpinner,
        BLink   
    } from 'bootstrap-vue'

import useListTable from '@/comp-functions/useListTable'
import useLocalization from '@/comp-functions/utils/useLocalization'
import { formatDatepicker } from '@/utils/formatter'

    export default {
        components: {
            BCard,
            BRow,
            BCol,
            BFormInput, 
            BButton,
            BTable,
            BSpinner,
            BLink
        },
      setup () {
        const { $t } = useLocalization()
        // Table Handlers
        const tableColumns = [
            { key: 'code', label: $t('apps.transactions.evaluation.singular.code') },
            { key: 'date', label: $t('apps.transactions.evaluation.singular.date') }, 
            { key: 'getContact.brand', label: $t('apps.transactions.evaluation.singular.supplier') },   
            { key: 'actions', label: $t('apps.transactions.evaluation.singular.print'), thClass: 'text-center', tdClass: 'text-center' }
        ]

        
        // Table Handlers
        const columnToBeFilter = [
          'code',
          'remark'
        ]

        const { searchQuery,
                refListTable,
                itemLists,
                perPage,
                currentPage,
                isBusy,
                isSortDirDesc
            } = useListTable({ url: 'transaction/evaluations' })
        return {
            searchQuery,
            refListTable,
            columnToBeFilter,
            tableColumns,
            itemLists,
            formatDatepicker,
            perPage,
            currentPage,
            isBusy,
            isSortDirDesc
        }
      }
    }

</script>